import { CurrencyDisplay } from 'vue-currency-input';

export const DEFAULT_PER_PAGE_LIMIT = 20;

export const DEFAULT_CURRENCY = 'RUB';

export const CURRENCY_INPUT_OPTIONS = {
  currency: DEFAULT_CURRENCY,
  hideGroupingSeparatorOnFocus: true,
  hideCurrencySymbolOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
  currencyDisplay: CurrencyDisplay.hidden,
};

export const LANDING_HOST = 'https://storebusters.ru';
export const CLIENTAPP_HOST = 'https://storebusters.ru/clientapp';
